body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* LocationPermissionChecker.css */

.permission-checker-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.permission-checker-title {
  color: #333;
  margin-bottom: 1rem;
}

.permission-alert {
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
}

.permission-alert-icon {
  margin-right: 8px;
}

.permission-button {
  margin-top: 12px;
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.permission-button:hover {
  opacity: 0.9;
}

@media (max-width: 640px) {
  .permission-checker-container {
    padding: 16px;
  }

  .permission-checker-title {
    font-size: 1.5rem;
  }
}